<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
        <mdb-btn
          flat
          dark-waves
          icon="arrow-left"
          type="button"
          title="Regresar"
          @click="$router.push('/clientes')"
        >
          Regresar
        </mdb-btn>
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles del cliente</header>
          <div class="row mx-0 mt-2">
            <div class="col-12 col-md-4">
              <span class="small-block font-titulo">Nombre o razón social</span>
              <p class="text-capitalize">{{empresa.nom_emp}}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">RIF</span>
              <p class="text-capitalize">{{empresa.rif_emp}}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Relación</span>
              <p class="text-capitalize">{{empresa.nom_rel}}</p>
            </div>
          </div>
        </div>
        <h4 class="mt-2 d-inline">Sucursales</h4>
        <mdb-btn
          v-for="suc in sucursales"
          :key="'suc'+suc.id_suc"
          flat
          :class="['chip', {'primario activo': collapse === suc.id_suc}]"
          @click.native.prevent="[collapse === suc.id_suc ? collapse = 0 : collapse = suc.id_suc, idSucursalSeleccionada = suc.id_suc]"
        >
          {{suc.nom_suc}}
          <font-awesome-icon class="ml-1" v-if="(collapse === suc.id_suc)" icon="check-circle"/>
        </mdb-btn>
        <transition name="tabActiva-transicion">
          <div
            v-if="collapse"
            class="row mx-0 mb-4"
          >
            <div class="col-12 col-md-7 col-lg">
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_tcl && sucursales[idSucursalSeleccionada].nom_tcl.length">
                <span class="small-block font-titulo">
                  Tipo de sucursal
                </span>
                {{sucursales[idSucursalSeleccionada].nom_tcl}}
              </p>
              <div class="d-flex justify-content-between">
                <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sada_suc && sucursales[idSucursalSeleccionada].sada_suc.length">
                  <span class="small-block font-titulo">
                    Sada
                  </span>
                  {{sucursales[idSucursalSeleccionada].sada_suc}}
                </p>
                <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].prom_atraso" :class="[promedioDeAtraso(sucursales[idSucursalSeleccionada].prom_atraso).color]" >
                  <span class="small-block font-titulo">
                    Promedio de atraso
                  </span>
                  {{ promedioDeAtraso(sucursales[idSucursalSeleccionada].prom_atraso).prom}}
                </p>
              </div>
              <div v-if="Object.keys(sucursales[idSucursalSeleccionada].restricciones).length">
                <small class="small-block font-titulo">
                  Restricciones condición de pago
                </small>
                <div
                  v-for="(restriccion, r) in sucursales[idSucursalSeleccionada].restricciones"
                  :key="r"
                  class="mb-2"
                >
                  <span class="small-block">
                    {{restriccion.nom_cp}}
                    <mdb-btn
                      flat
                      dark-waves
                      icon="trash"
                      class="p-1 px-2"
                      title="Eliminar restricción"
                      @click.native.prevent="modalConfirmacionEliminar = true; conPago = restriccion.id_rescp"
                      >
                    </mdb-btn>
                  </span>
                  <span class="d-block">
                    {{ restriccion.obs_rescp }}
                  </span>
                </div>
              </div>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].dir_suc && sucursales[idSucursalSeleccionada].dir_suc.length">
                <span class="small-block font-titulo">
                  Dirección
                </span>
                  {{sucursales[idSucursalSeleccionada].dir_suc}}
                <a
                  v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].lon_suc && sucursales[idSucursalSeleccionada].lon_suc.length && sucursales[idSucursalSeleccionada].lat_suc && sucursales[idSucursalSeleccionada].lat_suc.length"
                  :href="`https://www.google.com/maps/search/?api=1&query=${sucursales[idSucursalSeleccionada].lat_suc},${sucursales[idSucursalSeleccionada].lon_suc}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat ripple-parent my-0 py-1 px-3"
                >
                  <font-awesome-icon icon="map-marked-alt" class="mr-1" />
                  Ver mapa
                </a>
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].est_zon && sucursales[idSucursalSeleccionada].ciu_zon && sucursales[idSucursalSeleccionada].sec_zon &&  sucursales[idSucursalSeleccionada].est_zon.length && sucursales[idSucursalSeleccionada].ciu_zon.length && sucursales[idSucursalSeleccionada].sec_zon.length">
                <span class="small-block font-titulo">
                  Zona
                </span>
                {{sucursales[idSucursalSeleccionada].est_zon}}, {{sucursales[idSucursalSeleccionada].ciu_zon}}, {{sucursales[idSucursalSeleccionada].sec_zon}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_res &&  sucursales[idSucursalSeleccionada].nom_res.length">
                <span class="small-block font-titulo">
                  Agente de retención
                </span>
                {{sucursales[idSucursalSeleccionada].nom_res}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].cod_suc && sucursales[idSucursalSeleccionada].cod_suc.length">
                <span class="small-block font-titulo">
                  Código de la surcursal en sistema de facturación
                </span>
                {{sucursales[idSucursalSeleccionada].cod_suc}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_suc && sucursales[idSucursalSeleccionada].ema_suc.length">
                <span class="small-block font-titulo">
                  Email de la sucursal
                </span>
                {{sucursales[idSucursalSeleccionada].ema_suc}}
              </p>
              <p class="d-flex justify-content-between align-items-center mb-1">
                <small class="small-block font-titulo">
                  Horarios
                </small>
              </p>
              <div v-if="Object.keys(sucursales[idSucursalSeleccionada].horarios).length">
                <div
                  v-for="(dia, d) in sucursales[idSucursalSeleccionada].horarios"
                  :key="d+idSucursalSeleccionada"
                  class="mb-2"
                >
                  <span class="small-block">
                    {{diasDeLaSemana[d]}}
                  </span>
                  <span
                    v-for="horario in dia"
                    :key="horario.id_hor"
                    class="d-block"
                  >
                    {{ formatearHora(horario.hor_ini_hor) }} - {{ formatearHora(horario.hor_fin_hor) }}
                  </span>
                </div>
              </div>
              <template v-else>
                <p class="text-caption">Sin horarios registrados</p>
              </template>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_ven && sucursales[idSucursalSeleccionada].nom_per_ven.length">
                <span class="small-block font-titulo">
                  Vendedor
                </span>
                {{sucursales[idSucursalSeleccionada].nom_per_ven}} {{sucursales[idSucursalSeleccionada].ape_per_ven}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_enc && sucursales[idSucursalSeleccionada].nom_per_enc.length">
                <span class="small-block font-titulo">
                  Encargado
                </span>
                {{sucursales[idSucursalSeleccionada].nom_per_enc}} {{sucursales[idSucursalSeleccionada].ape_per_enc}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ced_enc && sucursales[idSucursalSeleccionada].ced_enc.length">
                <span class="small-block font-titulo">
                  Cédula del encargado
                </span>
                {{sucursales[idSucursalSeleccionada].ced_enc}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_enc && sucursales[idSucursalSeleccionada].ema_enc.length">
                <span class="small-block font-titulo">
                  Email del encargado
                </span>
                {{sucursales[idSucursalSeleccionada].ema_enc}}
              </p>
              <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].tel_suc && sucursales[idSucursalSeleccionada].tel_suc.length">
                <span class="small-block font-titulo">
                  Teléfono de la sucursal
                </span>
                {{sucursales[idSucursalSeleccionada].tel_suc}}
                <mdb-btn
                  flat
                  dark-waves
                  icon="phone"
                  :class="['m-0 py-1 px-3', {'ml-2' : sucursales[idSucursalSeleccionada].tel_suc.length < 12}]"
                  tag="a"
                  :href="`tel:+${sucursales[idSucursalSeleccionada].tel_suc}`"
                />
                <mdb-btn
                  flat
                  dark-waves
                  icon="envelope"
                  class="m-0 py-1 px-3"
                  tag="a"
                  :href="`sms:+${sucursales[idSucursalSeleccionada].tel_suc}`"
                />
                <mdb-btn
                  flat
                  dark-waves
                  fab icon="whatsapp"
                  class="m-0 py-1 px-3"
                  tag="a"
                  :href="`https://wa.me/${sucursales[idSucursalSeleccionada].tel_suc}`"
                />
              </p>
              <div v-if="contactos[idSucursalSeleccionada] !== 'undefined'">
                <div
                  v-for="con in contactos[idSucursalSeleccionada]"
                  :key="'adi'+con.id_dat"
                  class="mb-2"
                >
                  <span class="small-block font-titulo">
                    {{con.obs_dat}}
                    <mdb-badge
                      v-if="con.tel_cat || false"
                      pill
                      color="unique-color-dark"
                      class="z-depth-0"
                    >
                      {{ con.tel_cat }}
                    </mdb-badge>
                  </span>
                  {{con.cam_dat}}
                  <mdb-btn
                    flat
                    dark-waves
                    icon="phone"
                    class="m-0 py-1 px-3"
                    tag="a"
                    :href="`tel:+${con.cam_dat}`"
                  />
                  <mdb-btn
                    flat
                    dark-waves
                    icon="envelope"
                    class="m-0 py-1 px-3"
                    tag="a"
                    :href="`sms:+${con.cam_dat}`"
                  />
                  <mdb-btn
                    flat
                    dark-waves
                    fab icon="whatsapp"
                    class="m-0 py-1 px-3"
                    tag="a"
                    :href="`https://wa.me/${con.cam_dat}`"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7 col-xl-8 mt-lg-0 mt-4 px-lg-0">
              <ul
                v-if="facturas[idSucursalSeleccionada]"
                class="table p-sucursal"
              >
                <li class="encabezado sticky">
                  <div class="d-flex flex-wrap justify-content-center">
                    <h4 class="col-12 col-sm text-center m-2">Últimas facturas</h4>
                    <div class="col-auto">
                      <mdb-btn
                        flat
                        dark-waves
                        icon="sync"
                        class="p-2"
                        @click="modalRestringirTP = {
                          mostrarModal: true,
                          idSuc: idSucursalSeleccionada
                        }"
                      >
                        Restingir condición de pago
                      </mdb-btn>
                    </div>
                  </div>
                  <div class="thead claro">
                    <div class="col-sm col-md-3">
                      Fecha de emisión
                      <span class="small-block">
                        Fecha de vencimiento
                      </span>
                    </div>
                    <div class="col-12 col-sm-3">
                      Monto
                      <span class="small-block">
                        N° de factura
                      </span>
                    </div>
                    <div class="col-12 col-sm-2">
                      Fecha de pago
                      <span class="small-block">
                        Pagos
                      </span>
                    </div>
                    <div class="col-12 col-sm">
                      Estatus
                      <span class="small-block">
                        Observaciones
                      </span>
                    </div>
                  </div>
                </li>
                <li
                  v-for="(factura, p) in facturas[idSucursalSeleccionada]"
                  :key="'ped'+factura.id_fac+p"
                  class="contenido"
                >
                  <div
                    class="col-sm col-md-3"
                    data-columna="Fecha de emisión / Fecha de vencimiento"
                  >
                    <p>
                      {{ formatearFecha(factura.fec_cre_fac).ddmmyyyy}}
                      <span
                        v-if="factura.fec_ven_fac"
                        :class="[diasEstatusFac(factura.fec_ven_fac, factura.fec_pag_fac).color, 'd-block font-weight-bold']"
                      >
                        <p> {{formatearFecha(factura.fec_ven_fac).ddmmyyyy }} </p>
                        <p>
                          {{diasEstatusFac(factura.fec_ven_fac, factura.fec_pag_fac).dias}} días
                        </p>
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm-3"
                    data-columna="Monto / N° de factura"
                  >
                    <p>
                      {{factura.mon_fac}}
                      <span class="small-block">
                        {{factura.num_fac}}
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm-2"
                    data-columna="Fecha de pago / Pagos"
                  >
                    <p v-if="factura.fec_pag_fac">
                      {{ formatearFecha(factura.fec_pag_fac).ddmmyyyy }}
                      <span class="small-block">
                        {{factura.pagos}}
                      </span>
                    </p>
                    <p v-else>
                      Sin pagos relacionados
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm"
                    data-columna="Estatus / Observaciones"
                  >
                    <div class="row align-items-center">
                      <p class="col">
                        <span :class="['font-weight-bold', asignarColorEstado(factura.est_fac)]">
                          {{factura.est_fac}}
                        </span>
                        <small :class="['d-block', {'text-muted font-italic user-select-none': !factura.obs_fac }]">
                          {{factura.obs_fac ? factura.obs_fac  : 'Sin especificar'}}
                        </small>
                      </p>
                      <mdb-btn
                        flat
                        dark-waves
                        icon="pen"
                        class="p-1 px-2"
                        title="Editar estatus de factura"
                        @click="mostrarModalEditarFactura= true, facturaSeleccionada = factura"
                      >
                      </mdb-btn>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                v-else
                class="mensaje-no-items pb-3"
              >
                <h4 class="text-center m-2">Últimas facturas</h4>
                <font-awesome-icon
                  icon="clipboard"
                  size="5x"
                  class="icono"
                />
                <p class="texto">No hay facturas</p>
              </div>
              <ul
                v-if="pagos[idSucursalSeleccionada]"
                class="table p-sucursal"
              >
                <li class="encabezado sticky">
                  <h4 class="text-center m-2">Últimos pagos</h4>
                  <div class="thead claro">
                    <div class="col-sm-3 col-md-2">
                      Fecha
                      <span class="small-block">
                        del pago
                      </span>
                    </div>
                    <div class="col-12 col-sm-3">
                      Banco
                      <span class="small-block">
                        Método - Referencia
                      </span>
                    </div>
                    <div class="col-12 col-sm text-right">
                      Monto
                      <span class="small-block">
                        Tasa de cambio
                      </span>
                    </div>
                    <div class="col-12 col-sm text-center">
                      Estatus
                    </div>
                  </div>
                </li>
                <li
                  v-for="(pago, p) in pagos[idSucursalSeleccionada]"
                  :key="'ped'+pago.id_aco+p"
                  class="contenido cursor-pointer"
                  @click="$router.push(`/pagos/${pago.id_aco}`)"
                >
                  <div
                    class="col-sm-3 col-md-2"
                    data-columna="Fecha del pago"
                  >
                    {{formatearFecha(pago.fec_rec_aco).ddmmyyyy}}
                  </div>
                  <div
                    class="col-12 col-sm-3"
                    data-columna="Banco / Método - referencia"
                  >
                    <p>
                      {{pago.nom_cba}}
                      <span class="small-block">
                        {{pago.nom_mep}} - {{pago.ref_aco}}
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm text-sm-right"
                    data-columna="Estado / Monto"
                  >
                    <p>
                      {{pago.mon_aco}}
                      <span class="small-block">
                        {{pago.tas_aco}}
                      </span>
                    </p>
                  </div>
                  <div
                    class="col-12 col-sm text-sm-center text-break font-weight-bold"
                    data-columna="Estatus"
                  >
                    <p :class="asignarColorEstado(pago.est_aco)">{{pago.est_aco}}</p>
                  </div>
                </li>
              </ul>
              <div
                v-else
                class="mensaje-no-items pb-3"
              >
                <h4 class="text-center m-2">Últimos pagos</h4>
                <font-awesome-icon
                  icon="clipboard"
                  size="5x"
                  class="icono"
                />
                <p class="texto">No hay pagos</p>
              </div>
              <ul
                class="table p-sucursal mt-3"
                v-if="pedidos[idSucursalSeleccionada]"
              >
                <li class="encabezado sticky">
                  <h4 class="text-center m-2">Promedio últimos 5 pedidos</h4>
                  <div class="thead claro">
                    <div class="col">
                      Producto
                      <span class="small-block">Descripción - Categoría</span>
                    </div>
                    <div class="col-3 col-sm-4 text-right">
                      Promedio de cantidad
                    </div>
                  </div>
                </li>
                <li
                  v-for="(p, i) in pedidos[idSucursalSeleccionada]"
                  :key="i"
                  class="contenido hover-claro px-0"
                >
                  <div
                    data-columna="Producto"
                    class="col-12 col-sm pr-1"
                  >
                    <p>
                      {{ p.nom_pro }}
                      <span class="d-none d-sm-block small-block text-uppercase">
                        {{ p.des_pre }} - {{ p.nom_cat }}
                      </span>
                    </p>
                  </div>
                  <div
                    data-columna="Descripción"
                    class="col-12 d-sm-none"
                  >
                    {{ p.des_pre }}
                  </div>
                  <div
                    data-columna="Categoría"
                    class="col-12 d-sm-none"
                  >
                    {{ p.nom_cat }}
                  </div>
                  <div
                    class="col-12 col-sm-4 text-sm-right"
                    data-columna="Promedio de cantidad"
                  >
                    {{ Number(p.pro_can).toFixed(2) }}
                  </div>
                </li>
              </ul>
              <div
                v-else
                class="mensaje-no-items pb-3"
              >
                <h4 class="text-center m-2">Promedio últimos 5 pedidos</h4>
                <font-awesome-icon
                  icon="clipboard"
                  size="5x"
                  class="icono"
                />
                <p class="texto">No hay pedidos</p>
              </div>
            </div>
          </div>
          <div
            v-else-if="!collapse && Object.values(sucursales).length"
            class="mensaje-no-items pt-4 pb-5"
          >
            <font-awesome-icon
              icon="store"
              size="5x"
              class="icono"
            />
            <p class="texto text-center">
              Seleccione una sucursal para
              <span class="d-block">
                ver sus detalles
              </span>
            </p>
          </div>
        </transition>
      </div>
    </div>
    <ModalRestringirTipoPre
      :mostrarModal="modalRestringirTP.mostrarModal"
      :idSucursalSeleccionada="modalRestringirTP.idSuc"
      @cargaInicial="cargaInicial"
      @cerrar="modalRestringirTP = {
        mostrarModal: false,
        idSuc:''
      }"
    />
    <ModalEditarFactura
      :mostrarModalEditarFactura="mostrarModalEditarFactura"
      :facturaSeleccionada="facturaSeleccionada"
      @cerrar="mostrarModalEditarFactura = $event"
      @actualizar="cargaInicial();mostrarModalEditarFactura = false"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      textoBotonConfirmar="Eliminar"
      titulo="¡Esta acción es irreversible!"
      mensaje="Se eliminará la restricción"
      @cerrar="modalConfirmacionEliminar = false;"
      @confirmar="eliminarRestriccionConPa"
    />
  </article>
</template>

<script>
import { mdbBtn, mdbBadge } from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, formatearFecha, diasEstatusFac, formatearHora } from '@/funciones/funciones.js'
import ModalEditarFactura from '@/components/Modals/ModalEditarFactura.vue'
import ModalRestringirTipoPre from '@/components/Modals/ModalRestringirTipoPre.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'ClienteView',
  components: {
    mdbBtn,
    mdbBadge,
    ModalEditarFactura,
    ModalRestringirTipoPre,
    AlertaMensaje,
    ConfirmacionEliminar,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      formatearFecha,
      formatearHora,
      diasEstatusFac,
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      idSucursalSeleccionada: 0,
      mostrarModalEditarFactura: false,
      facturaSeleccionada: {},
      catcon: [],
      sucursales: {},
      empresa: {},
      pagos: {},
      facturas: {},
      pedidos: {},
      contactos: {},
      devoluciones: {},
      collapse: 0,
      añadir: {
        estado: false,
        accion: ''
      },
      diasDeLaSemana: [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
      ],
      validarValores: {},
      cargando: false,
      modalConfirmacionEliminar: false,
      conPago: '',
      modalRestringirTP: {
        mostrarModal: false,
        idSuc: ''
      }
    }
  },
  watch: {
    sucursales (valor) {
      const listaSucursales = Object.values(valor)
      if (listaSucursales.length === 1) {
        this.collapse = listaSucursales[0].id_suc
        this.idSucursalSeleccionada = listaSucursales[0].id_suc
      }
    }
  },
  methods: {
    cargaInicial () {
      this.cargando = true
      this.resultados = {}
      apiPost({ s: 'empresa' }, 'cliente_id=' + this.$route.params.id)
        .then((res) => {
          this.enlistarSucursales(res.data.suc, res.data.promAtraso, res.data.hor, res.data.restricciones)
          this.enlistarPagos(res.data.pagos)
          this.enlistarFacturas(res.data.facturas)
          this.enlistarPedidos(res.data.prePedidas)
          this.enlistarContactos(res.data.con)
          this.catcon = res.data.catcon
          this.empresa = res.data.emp
        })
    },
    enlistarSucursales (data, atraso, horarios, restricciones) {
      const sucursal = {}
      data.forEach(s => {
        if (!sucursal[s.id_suc]) {
          sucursal[s.id_suc] = {
            id_suc: s.id_suc,
            nom_suc: s.nom_suc,
            sada_suc: s.sada_suc,
            nom_res: s.nom_res,
            cod_suc: s.cod_suc,
            tel_suc: s.tel_suc,
            ema_suc: s.ema_suc,
            dir_suc: s.dir_suc,
            est_zon: s.est_zon,
            ciu_zon: s.ciu_zon,
            sec_zon: s.sec_zon,
            nom_per_ven: s.nom_per_ven,
            sco_suc: s.sco_suc,
            nom_per_enc: s.nom_per_enc,
            ape_per_enc: s.ape_per_enc,
            lat_suc: s.lat_suc,
            lon_suc: s.lon_suc,
            email_dat: {},
            ema_enc: s.ema_enc,
            est_fac: s.est_fac,
            ced_enc: s.ced_enc,
            ape_per_ven: s.ape_per_ven,
            nom_tcl: s.nom_tcl,
            prom_atraso: 0,
            horarios: {},
            restricciones: []
          }
        }
      })
      horarios.forEach((h) => {
        if (sucursal[h.id_suc_hor]) {
          if (!sucursal[h.id_suc_hor].horarios[h.num_dia_hor]) {
            sucursal[h.id_suc_hor].horarios[h.num_dia_hor] = []
          }
          sucursal[h.id_suc_hor].horarios[h.num_dia_hor].push(h)
        }
      })
      restricciones.forEach((r) => {
        if (sucursal[r.id_suc_rescp]) sucursal[r.id_suc_rescp].restricciones.push(r)
      })
      atraso.forEach((a) => {
        if (sucursal[a.id_suc_fac]) sucursal[a.id_suc_fac].prom_atraso = a.promedio_dias_atraso
      })
      this.sucursales = sucursal
      this.cargando = false
    },
    enlistarFacturas (data) {
      const facturas = {}
      data.forEach(p => {
        if (!facturas[p.id_suc_fac]) {
          facturas[p.id_suc_fac] = []
        }
        facturas[p.id_suc_fac].push({
          est_fac: p.est_fac,
          fec_cre_fac: p.fec_cre_fac,
          fec_ven_fac: p.fec_ven_fac,
          fec_pag_fac: p.fec_pag_fac,
          fec_fac: p.fec_fac,
          id_fac: p.id_fac,
          num_fac: p.num_fac,
          mon_fac: p.mon_fac,
          obs_fac: p.obs_fac,
          pagos: p.pagos
        })
      })
      this.facturas = facturas
    },
    enlistarPedidos (data) {
      const pedidos = {}
      data.forEach(p => {
        if (!pedidos[p.id_suc_ped]) {
          pedidos[p.id_suc_ped] = []
        }
        pedidos[p.id_suc_ped].push({
          nom_pro: p.nom_pro,
          des_pre: p.des_pre,
          nom_cat: p.nom_cat,
          pro_can: p.promedio_cantidad
        })
      })
      this.pedidos = pedidos
    },
    enlistarPagos (data) {
      const pagos = {}
      data.forEach(p => {
        if (!pagos[p.id_suc]) {
          pagos[p.id_suc] = []
        }
        pagos[p.id_suc].push({
          est_aco: p.est_aco,
          fec_rec_aco: p.fec_rec_aco,
          id_aco: p.id_aco,
          nom_cba: p.nom_cba,
          nom_mep: p.nom_mep,
          ref_aco: p.ref_aco,
          mon_aco: p.mon_aco,
          sim_mon: p.sim_mon,
          tas_aco: p.tas_aco
        })
      })
      this.pagos = pagos
    },
    enlistarContactos (data) {
      const contacto = {}
      data.forEach((c) => {
        if (!contacto[c.id_suc]) {
          contacto[c.id_suc] = []
        }
        contacto[c.id_suc].push({
          id_dat: c.id_dat,
          cam_dat: c.cam_dat,
          obs_dat: c.obs_dat,
          tel_cat: c.nombre_catcon,
          id_catcon: c.id_catcon
        })
      })
      this.contactos = contacto
    },
    promedioDeAtraso (promedio) {
      if (Number(promedio) < 0) {
        return {
          prom: ((Number(promedio)).toFixed(2) * -1) + ' días adelantado',
          color: 'texto-exitoso'
        }
      } else if (Number(promedio) > 0) {
        return {
          prom: (Number(promedio)).toFixed(2) + ' días atrasado',
          color: 'texto-error'
        }
      } else {
        return {
          prom: (Number(promedio)).toFixed(2) + ' días atrasado',
          color: 'texto-exitoso'
        }
      }
    },
    eliminarRestriccionConPa () {
      this.botonDeshabilitado = true
      apiPost({ s: 'eliminarRestriccionConPagoMv' }, 'res=' + this.conPago)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Restricción eliminada correctamente',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.cargaInicial()
          } else {
            this.alertaMensaje = {
              contenido: 'No se pudo eliminar la restricción, intenta de nuevo',
              tipo: 'advertencia'
            }
          }
          this.botonDeshabilitado = false
        }).catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ocurrió un error eliminando la restricción.',
            tipo: 'error'
          }
        })
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>

<style lang="scss" scoped>
.table.p-sucursal {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
.ocultar-overflow {
  overflow: hidden;
}
</style>
