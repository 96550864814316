<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h5-responsive text-lg-left pl-4">
          Filtros de búsqueda
        </header>
        <div class="col-auto boton-primario pr-4">
          <mdbBtn
            color="primario"
            icon="plus"
            class="px-3 no-print"
            @click="FormularioPagos = true"
          >
            Nuevo Pago
          </mdbBtn>
        </div>
      </div>
      <form class="row filtros" @submit.prevent>
        <div class="col-12 col-sm-6 col-xl-4">
          <mdb-input
            v-model.trim="pagosAFiltar"
            type="text"
            label="Nombre o RIF"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-xl my-2">
          <v-select
            v-model="estatusFiltrar"
            :options="EstatusPago"
            :reduce="estatus => estatus.nombre"
            :getOptionLabel="option => option.nombre"
            placeholder="Selecciona"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Estatus del pago
              </label>
            </template>
            <template #option="{ nombre }">
              {{ nombre }}
            </template>
            <template #no-options v-if="estatusFiltrar && estatusFiltrar.length < 3">
              Selecciona un estatus
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl my-2">
          <v-select
            v-model.trim="bancoFiltrar"
            :options="BancosPago"
            :reduce="cuenta => cuenta.id_cba"
            :getOptionLabel="option => option.nom_cba"
            placeholder="Selecciona banco"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Bancos
              </label>
            </template>
            <template #option="{ nom_cba }">
              {{ nom_cba }}
            </template>
            <template #no-options v-if="bancoFiltrar && bancoFiltrar.length < 3">
              Selecciona un banco
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 col-sm-6 col-xl my-2">
          <v-select
            v-model="metodoFiltrar"
            :options="MetodosPago"
            :reduce="metodo => metodo.id_mep"
            :getOptionLabel="option => option.nom_mep"
            placeholder="Selecciona método"
            multiple
            class="outline-datalist"
          >
            <template #header>
              <label class="datalist-label">
                Método de pago
              </label>
            </template>
            <template #option="{ nom_mep }">
              {{ nom_mep }}
            </template>
            <template #no-options v-if="metodoFiltrar && metodoFiltrar.length < 3">
              Selecciona un método de pago
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <div class="col-12 px-0">
          <FiltrosTipo1
            :zonas="zonasFiltro"
            :ven="vendedoresFiltro"
            :responsive="{
              vendedor: 'col-12 col-md-6',
              mes: 'col-12 col-sm-4 col-md-6',
              desde: 'col-12 col-sm-4 col-md-3 col-lg',
              hasta: 'col-12 col-sm-4 col-md-3 col-lg'
            }"
            :mes="true"
            :desdeHasta="true"
            :mostrar-btn-restablecer="true"
            :restablecer-filtrado="restablecer"
            @mesAFiltrar="fechaPago = $event"
            @desdeAFiltrar="fechaDel = $event"
            @hastaAFiltrar="fechaHasta = $event"
            @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
            @vendedorAFiltrar="vendedorAFiltrar=$event; actualizarPagos(); sucCam = []"
            @eliminarFiltros="RestablecerFiltros"
          />
        </div>
      </form>
      <ul class="table pedidos">
        <li class="encabezado sticky">
          <div class="row align-items-center mx-0">
            <div class="col-12 col-sm px-0">
              <header class="h4-responsive">Reporte de pagos</header>
              <p class="descripcion">
                Pagos listados: {{pagos && Object.keys(pagos).length}}
              </p>
            </div>
            <div class="col-12 col-sm-auto px-0">
              <mdb-btn
                block
                flat
                dark-waves
                icon="print"
                class="px-3 no-print"
                @click="imprimirVista()"
              >
                Imprimir
              </mdb-btn>
            </div>
          </div>
          <div class="thead claro">
            <div class="col-12 col-sm-auto pr-sm-1 fecha">
              Día
              <span class="small-block">
                Mes
              </span>
            </div>
            <div class="col-12 col-sm col-md-4">
              Cliente - Sucursal
              <span class="small-block">
                N°pago - Zona
              </span>
            </div>
            <div class="col-12 col-sm">
              Banco - Método - Referencia
              <span class="small-block">
                Monto - Tasa cambio
              </span>
            </div>
            <div class="col-12 col-sm-3 text-sm-right">
              Estatus
            </div>
          </div>
        </li>
        <li
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </li>
        <!-- Mensaje para fecha sin pagos -->
        <div
          v-if="pagos && !Object.keys(pagos).length && !cargando"
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto">
            No hay pagos con el filtro seleccionado
          </p>
        </div>
        <li
          class="contenido cursor-pointer"
          v-for="p in pagos"
          :key="p.id_aco"
          @click="$router.push(`/pagos/${p.id_aco}`)"
        >
          <div
            class="col-12 col-sm-auto pr-sm-1 fecha"
            data-columna="Día / Mes"
          >
            <p>
              {{formatearFecha(p.fec_cre_aco).ddmm.slice(0, 2)}}
              <span class="small-block">
                {{mesEnLetra(p.fec_cre_aco)}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm col-md-4"
            data-columna="Cliente - Sucursal / #Pago - Zona"
          >
            <p>
              {{p.nom_emp}} - {{p.nom_suc}}
              <span class="small-block">
                {{p.id_aco}} - {{p.ciu_zon}}, {{p.est_zon}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm"
            data-columna="Banco - Método - #Referencia /  Monto - Tasa cambio"
          >
            <p>
              <span v-if="p.nom_cba">{{p.nom_cba}} -</span>  {{p.nom_mep}} <span v-if="p.ref_aco">#{{p.ref_aco}}</span>
              <span class="small-block">
                {{convertirNumeroAFixed(p.mon_aco)}} {{p.sim_mon}} - {{convertirNumeroAFixed(p.tas_aco)}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm-3 text-sm-right"
            data-columna="Estatus"
          >
            <div class="row justify-content-sm-end align-items-center">
              <p class="col-auto custom-width-lg pl-md-2">
                <span :class="[asignarColorEstado(p.est_aco), 'font-weight-bold']">
                  {{ p.est_aco.slice(0,1).toUpperCase() + p.est_aco.slice(1) }}
                </span>
              </p>
              <mdb-btn
                v-if="p.est_aco !== 'Auditado' && p.est_aco !== 'Confirmado'"
                flat
                dark-waves
                icon="sync"
                title="Cambiar estatus"
                size="sm"
                class="col-12 col-sm-auto mx-0 mr-sm-1 mr-lg-0 px-2 custom-order-lg text-left text-sm-center no-print"
                @click.native.stop="pagoSeleccionado = JSON.parse(JSON.stringify(p));mostrarModalCambiarEstatus= !mostrarModalCambiarEstatus"
              >
                Cambiar estatus
              </mdb-btn>
            </div>
          </div>
        </li>
      </ul>
    </article>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarModalCambiarEstatus"
      @show="estatusPago = pagoSeleccionado.est_aco; fecPago = pagoSeleccionado.fec_pag_fac"
      @close="estatusPago = ''; fecPago = ''; mostrarModalCambiarEstatus = false;"
    >
      <mdb-modal-header>
        <mdb-modal-title>Actualizar estatus del pago</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row">
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                v-model="estatusPago"
                id="estatus-select"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Seleccione
                </option>
                <option v-for="(estatus , i) in EstatusPago" :key="i"
                  :value="estatus.nombre"
                >
                  {{estatus.nombre}}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="estatusPago.length ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
          </div>
          <p v-if="pagoSeleccionado.est_fac" class="col-12 mt-3 mb-2 font-weight-bold">
            Actualizar estatus de factura
          </p>
          <p v-if="pagoSeleccionado.num_fac" class="col-12 col-sm-6">
            {{pagoSeleccionado.num_fac}}
            <small class="d-block text-muted text-uppercase">Número de factura</small>
          </p>
          <div
            v-if="pagoSeleccionado.est_fac"
            :class="['col-12', {'col-sm-6': pagoSeleccionado.num_fac || pagoSeleccionado.est_fac == 'Solvente'}]"
          >
            <div class="md-form md-outline outline-select my-1">
              <select
                v-model="pagoSeleccionado.est_fac"
                id="estatus-select"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Selecciona estatus
                </option>
                <option
                  v-for="estatus in EstatusFactura"
                  :key="estatus.nombre"
                  :value="estatus.nombre"
                >
                  {{ estatus.nombre }}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="pagoSeleccionado.est_fac && pagoSeleccionado.est_fac.length ? 'label-active' : 'label-inactive'"
              >
                Nuevo estatus
              </label>
            </div>
          </div>
          <div
            v-if="pagoSeleccionado.est_fac == 'Solvente'"
            :class="['col-12', {'col-sm-6': !pagoSeleccionado.num_fac}]"
          >
            <mdb-input
              v-model="fecPago"
              type="date"
              label="Fecha de pago"
              class="my-2"
              invalidFeedback="Si la factura esta solvente, indica la fecha del pago. Debe ser antes de la fecha de hoy"
              outline
            />
          </div>
        </div>
        <div class="row justify-content-around mt-3">
          <mdb-btn
            type="submit"
            form="form-cliente"
            color="primario"
            :icon="'sync'"
            :icon-class="[{'fa-spin': botonDeshabilitado}]"
            :disabled="botonDeshabilitado"
            @click="cambiarEstatusPago"
          >
            <span>Actualizar</span>
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ModalFormularioPagos
      :mostrarModalFormPagos="FormularioPagos"
      :BancosPago="BancosPago"
      :MetodosPago="MetodosPago"
      :MonedasPago="MonedasPago"
      @crearPago="obtenerDatosPagos()"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="FormularioPagos = false"
    />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view
        @actualizar="obtenerDatosPagos"
      />
    </transition>
  </section>
</template>

<script>
import {
  EstatusPago, EstatusPagoIconos, EstatusFactura
} from '@/constantes/pagos.js'
import {
  mdbInput,
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import { asignarColorEstado, formatearFecha, convertirNumeroAFixed, comprobarRutaHija } from '@/funciones/funciones.js'
import LoaderKel from '@/components/LoaderKel.vue'
import ModalFormularioPagos from '@/components/Modals/FormularioPagos.vue'
export default {
  name: 'PagosView',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    FiltrosTipo1,
    LoaderKel,
    ModalFormularioPagos,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle
  },
  data () {
    return {
      convertirNumeroAFixed,
      EstatusFactura,
      EstatusPago,
      EstatusPagoIconos,
      asignarColorEstado,
      formatearFecha,
      pagos: [],
      fechaPago: '',
      fechaDel: '',
      fechaHasta: '',
      pagosAFiltar: '',
      bancoFiltrar: '',
      metodoFiltrar: '',
      estatusFiltrar: '',
      mes: {},
      clientes: [],
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      pagoId: '',
      alertaMensaje: {
        contenido: ''
      },
      restablecer: false,
      zonasFiltro: [],
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      vendedoresFiltro: [],
      vendedorAFiltrar: [],
      cargando: false,
      // datos para el formulario de pagos
      BancosPago: [],
      MetodosPago: [],
      MonedasPago: [],
      FormularioPagos: false,
      // Modal cambiar estatus al pago
      estatusPago: '',
      fecPago: '',
      mostrarModalCambiarEstatus: false,
      pagoSeleccionado: {}
    }
  },
  mounted () {
    this.obtenerDatosPagos()
  },
  computed: {
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    }
  },
  watch: {
    fechaPago () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizarPagos()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizarDeHasta()
    },
    fechaDel () {
      if (!this.fechaHasta) return
      this.actualizarDeHasta()
    },
    bancoFiltrar () {
      this.actualizarPagos()
    },
    metodoFiltrar () {
      this.actualizarPagos()
    },
    pagosAFiltar () {
      this.actualizarPagos()
    },
    estatusFiltrar () {
      this.actualizarPagos()
    }
  },
  methods: {
    async obtenerDatosPagos () {
      this.cargando = true
      try {
        const { data } = await apiPost({ s: 'cobrosBase' }, '')
        this.BancosPago = data.cuentas
        this.MetodosPago = data.metodos
        this.MonedasPago = data.monedas
        this.zonasFiltro = data.zonas
        this.vendedoresFiltro = data.vendedores
        this.pagos = data.cobros
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error al obteniendo los bancos',
          tipo: 'error'
        }
      } finally {
        this.cargando = false
      }
    },
    cambiarEstatusPago () {
      this.botonDeshabilitado = true
      const fec_pag_fac = JSON.parse(JSON.stringify(this.fecPago))
      const est = JSON.parse(JSON.stringify(this.estatusPago))
      const variablesPost = {
        aco: this.pagoSeleccionado.id_aco,
        est
      }
      apiPost({ s: 'pagoEditar' }, JSON.stringify(variablesPost))
        .then(({ data }) => {
          if (data && data.r) {
            this.pagos.map((p) => {
              const pago = p
              if (p.id_aco === this.pagoSeleccionado.id_aco) {
                pago.est_aco = est
              }
              return pago
            })
            this.mostrarModalCambiarEstatus = false
            this.botonDeshabilitado = false
            const factura = {
              est_fac: this.pagoSeleccionado.est_fac,
              fac: this.pagoSeleccionado.id_fac,
              fec_pag_fac
            }
            apiPost({ s: 'facturaEditarM' }, JSON.stringify(factura))
              .then(({ data }) => {
                if (data && data.r) {
                  this.alertaMensaje = {
                    contenido: 'El estatus ha sido actualizado correctamente',
                    tipo: 'correcto'
                  }
                  this.pagos.map((p) => {
                    const pago = p
                    if (p.id_aco === this.pagoSeleccionado.id_aco) {
                      pago.est_fac = this.pagoSeleccionado.est_fac
                    }
                    return pago
                  })
                }
              })
              .catch(() => {
                this.botonDeshabilitado = false
                this.alertaMensaje = {
                  contenido: 'Ha ocurrido un error cambiando el estatus de la factura, por favor intenta de nuevo',
                  tipo: 'error'
                }
              })
          }
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error cambiando el estatus del pago, por favor intenta de nuevo',
            tipo: 'error'
          }
        })
      this.estatusPago = ''
      this.mostrarModalCambiarEstatus = false
      this.botonDeshabilitado = false
    },
    actualizarPagos () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.pedidos = {}
        this.cargando = true
        let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : ''
        data += this.fechaPago ? 'y=' + this.fechaPago.split('-')[0] + '&m=' + this.fechaPago.split('-')[1] : ''
        data += this.fechaHasta && this.fechaDel && this.fechaHasta.length && this.fechaDel.length ? '&del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
        data += Object.keys(this.bancoFiltrar).length ? '&bancos=' + this.bancoFiltrar : ''
        data += Object.keys(this.metodoFiltrar).length ? '&metodo=' + this.metodoFiltrar : ''
        data += Object.keys(this.estatusFiltrar).length ? '&estatus=' + this.estatusFiltrar : ''
        data += this.pagosAFiltar.length ? '&nombre=' + this.pagosAFiltar : ''
        data += this.vendedorAFiltrar.length ? '&vendedores=' + this.vendedorAFiltrar : ''
        data += this.estadosAFiltrar.length ? '&estados=' + this.estadosAFiltrar : ''
        data += this.municipiosAFiltrar.length ? '&municipios=' + this.municipiosAFiltrar : ''
        data += this.parroquiasAFiltrar.length ? '&parroquias=' + this.parroquiasAFiltrar : ''
        apiPost({ s: 'cobrosBase' }, data)
          .then((r) => {
            this.pagos = r.data.cobros
            this.cargando = false
          })
      }, 500) // delay
    },
    actualizarDeHasta () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : ''
        data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
        data += Object.keys(this.bancoFiltrar).length ? '&bancos=' + this.bancoFiltrar : ''
        data += Object.keys(this.metodoFiltrar).length ? '&metodo=' + this.metodoFiltrar : ''
        data += this.pagosAFiltar.length ? '&nombre=' + this.pagosAFiltar : ''
        data += Object.keys(this.estatusFiltrar).length ? '&estatus=' + this.estatusFiltrar : ''
        apiPost({ s: 'cobrosBase' }, data)
          .then((r) => {
            this.pagos = r.data.cobros
          })
      }, 500) // delay
    },
    mesEnLetra (fechaPlana) {
      const mess = new Date(fechaPlana)
      const opt = { month: 'short' }
      const mesLetras = mess.toLocaleDateString('es-VE', opt)
      return mesLetras
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.actualizarPagos()
    },
    RestablecerFiltros () {
      this.restablecer = true
      this.fechaDel = ''
      this.fechaHasta = ''
      this.pagosAFiltar = ''
      this.bancoFiltrar = ''
      this.metodoFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.vendedorAFiltrar = []
      this.fechaPago = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
      this.actualizarPagos()
      setTimeout(() => {
        this.restablecer = false
      }, 500)
    },
    filtroNombre (search) {
      if (!search) {
        this.clientes = []
      } else {
        apiPost({ s: 'cobrosBase' }, 'nom=' + search)
          .then((r) => {
            if (r.data.nfil) {
              this.clientes = r.data.nfil
            } else {
              this.clientes = []
            }
          })
      }
    },
    imprimirVista () {
      window.print()
    }
  }
}

</script>

<style lang="scss" scoped>
.filtros {
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    margin: auto .5rem 1.5rem;
  }
}
.table.pedidos {
  margin: 0 auto 6rem;
  // Define los breakpoints para el responsive de ul.table
  @media screen {
    @include ul-table-responsive-at ("576px");
  }

  .fecha {width: 44px;}

  .custom-width-lg {
    @media (min-width: 992px) and (max-width: 1129px) {
      text-align: right;
      width: 100%;
    }
  }
  .custom-order-lg {
    @media (min-width: 1130px) {
      order: -1;
    }
  }
}
</style>
