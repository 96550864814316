<template>
<section>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModalFormPagos"
    @close="$emit('cerrar', false); limpiarCampos()"
  >
    <mdb-modal-header class="pb-0">
      <mdb-modal-title>
        Datos del pago
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body id="formPagos">
      <transition
        name="fade-resumen"
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutLeft"
        mode="out-in"
      >
        <div v-if="!resumenPago" key="campos">
          <header class="font-weight-bold">Información del cliente</header>
          <div class="row align-items-center">
            <div class="col-12 col-lg-6 my-2">
              <v-select
                v-model="pago.emp"
                :options="clientes"
                :reduce="cliente => cliente"
                :getOptionLabel="clientes => clientes.nom_emp"
                @input="inputEmpresas()"
                @search="query=> query && query.trim() != '' ? obtenerClientes(query, false, 500) : pago.suc = ''"
                placeholder="Selecciona cliente"
                :class="['outline-datalist', validarValores.emp ? `${validarValores.emp}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Nombre del cliente
                  </label>
                </template>
                <template #option="{nom_emp}">
                  {{ nom_emp }}
                </template>
                <template #no-options>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>
            <div class="col-12 col-lg-6 my-2">
              <v-select
                v-model="pago.suc"
                :options="sucursales"
                :reduce="sucursal => sucursal"
                :getOptionLabel="sucursal => sucursal.nom_suc"
                @input="!pago.suc ? sucursales = [] : suc = pago.suc"
                placeholder="Selecciona sucursal"
                :class="['outline-datalist', validarValores.suc ? `${validarValores.suc}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Nombre de la Empresa - Sucursal
                  </label>
                </template>
                <template #option="{nom_suc}">
                  {{ nom_suc }}
                </template>
                <template #no-options>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>
            <div class="col-12 col-sm-6">
              <!-- validar que la fecha no sea mayor a hoy -->
              <div
                :class="['md-form md-outline my-2',
                  validarValores.fecRe ? `${validarValores.fecRe}-date-time` : ''
                ]"
              >
                <input
                  id="fecha-recibo"
                  v-model="pago.fecRe"
                  type="date"
                  :max="fechaActual"
                  aria-label="Fecha recibido"
                  class="form-control"
                >
                <label
                  for="fecha-recibo"
                  :class="['mr-5', {'label-active': pago.fecRe && pago.fecRe.length}]"
                >
                  Fecha recibido
                </label>
              </div>
            </div>
            <!-- validar que la fecha no sea mayor a hoy -->
            <div class="col-12 col-sm-6">
              <div
                :class="['md-form md-outline my-2',
                  validarValores.fecDe ? `${validarValores.fecDe}-date-time` : ''
                ]"
              >
                <input
                  id="fecha-deposito"
                  v-model="pago.fecDe"
                  type="date"
                  :max="fechaActual"
                  aria-label="Fecha de deposito"
                  class="form-control"
                >
                <label
                  for="fecha-deposito"
                  :class="['mr-5', {'label-active': pago.fecDe && pago.fecDe.length}]"
                >
                  Fecha de depósito
                </label>
              </div>
            </div>
          </div>
          <header class="font-weight-bold">Información del pago</header>
          <div class="row">
            <div :class="['col-12', {'col-sm-6': !(pago.mep && pago.mep.req_cba_mep==='1')}, 'col-lg-4 my-2']">
              <v-select
                v-model="pago.mep"
                :options="MetodosPago"
                :reduce="metodo => metodo"
                :getOptionLabel="option => option.nom_mep"
                placeholder="Selecciona método"
                :class="['outline-datalist', validarValores.mep ? `${validarValores.mep}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Método de pago
                  </label>
                </template>
                <template #option="{ nom_mep }">
                  {{ nom_mep }}
                </template>
                <template #no-options v-if="pago.mep && pago.mep.length < 3">
                  Selecciona método
                </template>
                <template #no-options v-else>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>
            <div class="col-12 col-lg-8 my-2" v-if="pago.mep && pago.mep.req_cba_mep==='1'">
              <v-select
                v-model="pago.cba"
                :options="BancosPago"
                :reduce="cuenta => cuenta"
                :getOptionLabel="option => option.nom_cba"
                placeholder="Selecciona cuenta"
                :class="['outline-datalist', validarValores.cba ? `${validarValores.cba}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Cuenta Bancaria
                  </label>
                </template>
                <template #option="{ nom_cba, num_cba, ide_cba }">
                  <span class="font-weight-bold">{{ nom_cba }}</span> - {{ num_cba }} - V-{{ide_cba}}
                </template>
                <template #no-options v-if="pago.cba && pago.cba.length < 3">
                  Selecciona cuenta
                </template>
                <template #no-options v-else>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>

            <div class="col-12 col-sm-6 col-lg-4 my-2" v-if="pago.mep && pago.mep.req_cba_mep==='0'">
              <v-select
                v-model="pago.mone"
                :options="MonedasPago"
                :reduce="moneda => moneda"
                :getOptionLabel="option => option.nom_mon"
                placeholder="Selecciona la moneda"
                :class="['outline-datalist', validarValores.mone ? `${validarValores.mone}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Moneda
                  </label>
                </template>
                <template #option="{ nom_mon, sim_mon }">
                  {{ nom_mon }} {{sim_mon}}
                </template>
                <template #no-options v-if="pago.mone && pago.mone.length < 3">
                  Selecciona moneda
                </template>
                <template #no-options v-else>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>
            <div class="col-12 col-sm-6 col-lg-4"  v-if="pago.mep && pago.mep.req_cba_mep==='1'">
              <mdb-input
                v-model="pago.ref"
                type="number"
                label="Referencia"
                placeholder="Ej: 123456789"
                invalidFeedback="Escribe una referencia válida"
                :class="['my-2', validarValores.ref]"
                outline
              />
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
              <div
                :class="[
                  'md-form md-outline outline-select my-2',
                  validarValores.est ? `${validarValores.est}-select` : '',
                ]"
              >
                <select
                  v-model="pago.est"
                  id="estatus-select"
                  class="custom-select"
                >
                  <option class="d-none" value="" disabled>
                    Selecciona estatus
                  </option>
                  <option
                    v-for="estatus in EstatusPago"
                    :key="estatus.nombre"
                    :value="estatus.nombre"
                  >
                    {{ estatus.nombre }}
                  </option>
                </select>
                <label
                  for="estatus-select"
                  :class="pago.est && pago.est.length ? 'label-active' : 'label-inactive'"
                >
                  Estatus del pago
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
              <mdb-input
                v-model="pago.tas"
                type="number"
                :min="0"
                label="Tasa de cambio del día"
                placeholder="Ej: 4.50"
                invalidFeedback="Indica tasa de cambio del día de pago"
                :class="['my-2', validarValores.tas]"
                outline
              />
            </div>
            <div :class="['col-12 col-sm-6', {'col-lg-4': pago.mep && !(pago.mep.req_cba_mep==='1')}]">
              <mdb-input
                v-model="pago.mon"
                :min="0"
                type="number"
                label="Monto"
                placeholder="Ej: 99.00"
                invalidFeedback="Escribe el monto del pago"
                :class="['my-2', validarValores.mon]"
                outline
              />
            </div>
            <div class="col">
              <mdb-input
                v-model.number="pago.ind"
                type="number"
                label="Indexación"
                placeholder=""
                invalidFeedback="Escribe la Indexación"
                outline
                class="my-2"
              />
            </div>
            <div class="col-12">
              <mdb-input
                v-model="pago.obs"
                type="textarea"
                label="Observación"
                placeholder=""
                invalidFeedback="Escribe la observación"
                outline
                class="my-2"
              />
            </div>
            <!-- <div class="col-12">
              <mdb-input
                v-model="numeroFactura"
                type="textarea"
                label="Número/s de factura asociado"
                placeholder="Ej: 123456678, 00125488"
                small="Debes separar cada factura con una coma en el caso de ser más de una factura"
                outline
                class="my-2"
              />
            </div> -->
          </div>
          <header class="font-weight-bold">Información de la factura</header>
          <div class="row">
            <div :class="['col-12 my-2',pago.est_fac == 'Solvente' ? 'col-lg-6' : 'col-lg-8' ]">
              <v-select
                v-model="pago.fac"
                :options="facturas"
                :reduce="factura => factura"
                :getOptionLabel="option => option.num_fac"
                placeholder="Selecciona factura"
                :class="['outline-datalist', validarValores.fac ? `${validarValores.fac}-datalist` : '']"
              >
                <template #header>
                  <label class="datalist-label">
                    Factura
                  </label>
                </template>
                <template #option="{ num_fac, mon_fac, fec_ven_fac }">
                  <span class="font-weight-bold">{{ num_fac }}</span> - {{ mon_fac }} - {{fec_ven_fac}}
                </template>
                <template #no-options v-if="pago.fac && pago.fac.length < 3">
                  Selecciona factura
                </template>
                <template #no-options v-else>
                  No se encontraron resultados para la búsqueda
                </template>
              </v-select>
            </div>
            <div :class="['col-12', pago.est_fac == 'Solvente' ? 'col-md-6 col-lg-3' : 'col-lg-4']">
              <div
                :class="[
                  'md-form md-outline outline-select my-2',
                  validarValores.est_fac ? `${validarValores.est_fac}-select` : '',
                ]"
              >
                <select
                  v-model="pago.est_fac"
                  id="fac_estatus-select"
                  class="custom-select"
                >
                  <option class="d-none" value="" disabled>
                    Actualizar factura
                  </option>
                  <option
                    v-for="estatus in EstatusFactura"
                    :key="estatus.nombre"
                    :value="estatus.nombre"
                    :class="[asignarColorEstado(estatus.nombre), 'font-weight-bold']"
                  >
                    {{ estatus.nombre }}
                  </option>
                </select>
                <label
                  for="fac_estatus-select"
                  :class="pago.est_fac && pago.est_fac.length ? 'label-active' : 'label-inactive'"
                >
                  Nuevo estatus
                </label>
              </div>
            </div>
            <div
              v-if="pago.est_fac == 'Solvente'"
              class="col-12 col-md-6 col-lg-3"
            >
              <mdb-input
                v-model="pago.fec_pag_fac"
                type="date"
                label="Fecha de pago"
                :class="['my-2', validarValores.fec_pag_fac]"
                invalidFeedback="Si la factura esta solvente, indica la fecha del pago. Debe ser antes de la fecha de hoy"
                outline
              />
            </div>
            <div class="col-12">
              <mdb-input
                v-model="pago.obs_fac"
                type="textarea"
                label="Observación de factura"
                placeholder=""
                invalidFeedback="Escribe la observación"
                outline
                class="my-2"
              />
            </div>
          </div>
          <div v-if="pago.mon && (pago.tas && montoDivisas > 0)">
            <header class="font-weight-bold">Equivalencia del monto</header>
            <div class="row">
              <div :class="{'col-12 col-lg-6 active' : pago.mon}">
                {{ monedaPagoSeleccionado.sim_mon }}{{pago.mon}}
                <small class="d-block text-muted text-uppercase">Monto</small>
              </div>
              <div  :class="{'col-12 col-lg-6 active' : pago.tas}">
                {{ pago.tas }} - {{monedaPagoSeleccionado.sim_mon == '$' ? 'Bs' : '$'}}{{montoDivisas}}
                <small class="d-block text-muted text-uppercase">Tasa - Monto en divisas</small>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="detalles">
          <header class="col font-weight-bold my-2">Resumen del pago</header>
          <ul v-if="detallesResumenPago" class="lista-resumen-pago row justify-content-start">
            <li v-if="pago.emp && pago.suc" class="col-12 col-lg-6">
              {{ pago.emp.nom_emp }} - {{ pago.suc.nom_suc }}
              <small class="d-block text-muted text-uppercase">Cliente - Sucursal</small>
            </li>
            <li v-if="pago.cba" class="col-12 col-sm-6">
              {{ pago.cba.nom_cba }}
              <small class="d-block text-muted text-uppercase">Cuenta Bancaria</small>
            </li>
            <li v-if="pago.mep && pago.ref" class="col-12 col-sm-6">
              {{ pago.mep.nom_mep }} - {{ pago.ref }}
              <small class="d-block text-muted text-uppercase">Método de pago - # Referencia</small>
            </li>
            <li v-if="pago.mon" class="col-12 col-sm-6">
              {{ monedaPagoSeleccionado.sim_mon }}{{convertirNumeroAFixed(pago.mon)}}
              <small class="d-block text-muted text-uppercase">Monto</small>
            </li>
            <li v-if="pago.mone && Object.keys(pago.mone).length" class="col-12 col-sm-6">
              {{pago.mone && pago.mone.nom_mon}} / {{pago.mone && pago.mone.sim_mon}}
              <small class="d-block text-muted text-uppercase">Moneda</small>
            </li>
            <li v-if="pago.tas && montoDivisas > 0" class="col-12 col-sm-6">
              {{ convertirNumeroAFixed(pago.tas) }} - {{monedaPagoSeleccionado.sim_mon == '$' ? 'Bs' : '$'}}{{montoDivisas}}
              <small class="d-block text-muted text-uppercase">Tasa - Monto en divisas</small>
            </li>
            <li v-if="pago.fecRe || pago.fecDe" class="col-12 col-lg-6">
              {{ pago.fecRe && pago.fecDe ? pago.fecRe+' - '+pago.fecDe : pago.fecRe+ pago.fecDe }}
              <small class="d-block text-muted text-uppercase">
                {{ pago.fecRe && pago.fecDe ? 'Fecha de recibo - Fecha de depósito' : pago.fecRe ? 'Fecha recibido': 'Fecha de depósito'}}</small>
            </li>
            <li v-if="pago.est" :class="['col-12 col-sm-6 font-weight-bold', asignarColorEstado(pago.est)]">
              <font-awesome-icon :icon="EstatusPagoIconos[pago.est]" />
              {{ pago.est }}
              <small class="d-block text-muted text-uppercase">Estatus</small>
            </li>
            <li v-if="pago.ind" class="col-12 col-sm-6">
              {{pago.ind}}
              <small class="d-block text-muted text-uppercase">Indexación</small>
            </li>
            <li v-if="pago.obs" class="col-12 col-lg-6">
              {{ pago.obs }}
              <small class="d-block text-muted text-uppercase">Observación del pago</small>
            </li>
          </ul>
          <header class="col-12 font-weight-bold my-2">Resumen de la factura</header>
          <ul v-if="detallesResumenPago" class="lista-resumen-pago row justify-content-start">
            <li v-if="pago.fac.num_fac" class="col-12 col-md-6">
              {{pago.fac.num_fac}}
              <small class="d-block text-muted text-uppercase">Número de factura</small>
            </li>
            <li v-if="pago.fac.mon_fac" class="col-12 col-lg-6">
              {{ pago.fac.mon_fac }}
              <small class="d-block text-muted text-uppercase">Monto factura</small>
            </li>
            <li v-if="pago.est_fac" :class="['col-12 col-sm-6 font-weight-bold', asignarColorEstado(pago.est_fac)]">
              {{ pago.est_fac }}
              <small class="d-block text-muted text-uppercase">Estado de factura</small>
            </li>
            <li v-if="pago.obs_fac" class="col-12 col-lg-6">
              {{ pago.obs_fac }}
              <small class="d-block text-muted text-uppercase">Observación de la factura</small>
            </li>
          </ul>
        </div>
      </transition>
      <div class="row text-center justify-content-around">
        <div v-if="resumenPago" class="col-12 col-sm-auto">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="resumenPago = false"
          >
            Regresar
          </mdb-btn>
        </div>
        <div class="col-12 col-sm-auto">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            @click="$emit('cerrar', false), resumenPago = false"
          >
            Cancelar
          </mdb-btn>
        </div>
        <div v-if="!resumenPago" class="col-12 col-sm-auto">
          <mdb-btn
            color="primario"
            icon="angle-right"
            icon-right
            @click="validarPago(true)"
          >
            Continuar
          </mdb-btn>
        </div>
        <div v-else class="col-12 col-sm-auto">
          <mdb-btn
            color="primario"
            icon="check"
            @click="validarPago()"
          >
            {{esEditar ? 'Actualizar pago' : 'Agregar pago'}}
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</section>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import {
  capitalizar,
  eliminarVacios,
  asignarColorEstado,
  formatearFecha,
  convertirNumeroAFixed
} from '@/funciones/funciones.js'
import {
  EstatusPago, EstatusFactura, EstatusPagoIconos
} from '@/constantes/pagos.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput
} from 'mdbvue'
export default {
  name: 'FormularioPagos',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput
  },
  props: {
    mostrarModalFormPagos: {
      type: Boolean,
      required: true
    },
    BancosPago: {
      type: Array,
      required: true
    },
    MetodosPago: {
      type: Array,
      required: true
    },
    MonedasPago: {
      type: Array,
      required: true
    },
    pagoAEditar: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    esEditar: {
      type: Boolean,
      required: false
    },
    pagoId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      pago: {
        // Campos obligatorios
        mep: '', // (ID  del método de pago)
        emp: '', // (ID de la empresa)
        suc: '', // (ID de la sucursal)
        mon: '', // (monto)
        tas: '', // (tasa)
        mone: '', // (ID de la moneda)
        ref: '', // (referencia)
        est: '', // (estado del pago)
        fecRe: '', // (Fecha de recibo)
        fecDe: '', // (fecha depósito)
        cba: '', // Cuenta de banco
        fac: '', // factura
        fec_pag_fac: '', // fecha de la factura
        est_fac: '', // estatus factura
        obs_fac: '', // observaciones de factura
        // Campos opcionales
        obs: '', // observacion
        ind: '' // indexación (number)
      },
      resumenPago: false,
      convertirNumeroAFixed,
      EstatusPago,
      EstatusFactura,
      EstatusPagoIconos,
      asignarColorEstado,
      monedaPagoSeleccionado: '',
      validarValores: {},
      cargando: false,
      timeout: null,
      clientes: [],
      sucursales: [],
      vendedores: [],
      fechaActual: '',
      facturas: [],
      suc: ''
    }
  },
  mounted () {
    this.fechaActual = new Date().toISOString().split('T')[0]
  },
  computed: {
    montoDivisas () {
      const monto = (this.pago.mon / this.pago.tas)
      return monto && monto.toFixed()
    },
    detallesResumenPago () {
      return !!Object.values(this.pago).length
    }
  },
  watch: {
    mostrarModalFormPagos: function () {
      if (!this.mostrarModalFormPagos) {
        this.limpiarCampos()
      } else {
        if (this.esEditar) {
          this.rellenarDatosAnteriores()
        }
      }
    },
    suc: function () {
      if (this.suc !== '') {
        this.obtenerFacturas()
      }
    },
    sucursales: function () {
      if (!this.pago.suc) {
        this.pago.fac = ''
      }
    }
  },
  methods: {
    validarFechaMenorAHoy (fecha) {
      const hoy = new Date()
      const fechaFormulario = new Date(fecha)
      hoy.setHours(0, 0, 0, 0)
      if (hoy <= fechaFormulario) {
        this.mostrarAlerta('La fecha debe ser el día de hoy o antes de hoy', 'advertencia')
        return false
      }
      return true
    },
    rellenarDatosAnteriores () {
      if (this.pagoAEditar) {
        const pago = JSON.parse(JSON.stringify(this.pagoAEditar))
        const datosMetodo = this.MetodosPago.filter((c) => {
          return c.id_mep === pago.id_mep_aco
        })
        const datosCuenta = this.BancosPago.filter((c) => {
          return c.id_cba === pago.id_cba_aco
        })
        const datosMoneda = this.MonedasPago.filter((c) => {
          return c.id_mon === pago.id_mon_aco
        })
        this.obtenerClientes(pago.nom_emp, pago, 0)
        this.pago.aco = this.pagoId
        this.pago.mep = datosMetodo && datosMetodo[0]
        this.pago.cba = datosCuenta && datosCuenta[0]
        this.pago.mone = datosMoneda && datosMoneda[0]
        this.pago.mon = pago.mon_aco
        this.pago.tas = pago.tas_aco
        this.pago.ref = pago.ref_aco
        this.pago.est = pago.est_aco
        this.pago.fecRe = formatearFecha(pago.fec_rec_aco).yyyymmdd
        this.pago.fecDe = formatearFecha(pago.fec_dep_aco).yyyymmdd
        this.pago.obs = pago.obs_aco
        this.pago.ind = pago.ind_aco
        this.pago.fec_pag_fac = pago.fec_pag_fac
        this.pago.fac = {
          id_fac: pago.id_fac,
          num_fac: pago.num_fac,
          mon_fac: pago.mon_fac,
          fec_ven_fac: pago.fec_ven_fac,
          est_fac: pago.est_fac,
          fec_fac: pago.fec_fac
        }
        this.pago.est_fac = pago.est_fac
        this.pago.obs_fac = pago.obs_fac
      }
    },
    inputEmpresas () {
      if (!this.pago.emp) {
        this.pago.suc = ''
        this.sucursales = []
      } else {
        this.obtenerSucursalesDeEmpresa(this.pago.emp, 0)
      }
    },
    obtenerClientes (nombre, pago, delay) {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        const datas = `nombre=${nombre}`
        this.facturas = []
        apiPost({ s: 'empresasBuscar' }, datas)
          .then(({ data }) => {
            if (!data) return
            if (pago) {
              this.obtenerSucursalesDeEmpresa(data.emp[0], 0, pago.id_suc)
              return data.emp[0]
            }
            this.clientes = data.emp
          })
      }, delay) // delay
    },
    async obtenerSucursalesDeEmpresa (emp, delay, id_suc) {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        let datas = `emp=${emp.id_emp}`
        this.facturas = []
        if (id_suc) datas += `&s=${id_suc}`
        apiPost({ s: 'empresaSucursales' }, datas)
          .then(async ({ data }) => {
            if (!data) return
            if (id_suc) {
              // Rellenar datos del cliente y de la sucursal
              this.pago.emp = emp
              this.pago.suc = data.emp && data.emp[0]
              this.obtenerFacturas()
              return
            }
            this.sucursales = data.emp
          })
      }, delay) // delay
    },
    obtenerFacturas () {
      apiPost({ s: 'facturasMv' }, 'suc=' + this.pago.suc.id_suc + "&estatus='Por cobrar'")
        .then(({ data }) => {
          this.facturas = data
        })
    },
    mostrarAlerta (contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo
      })
    },
    limpiarCampos () {
      this.botonDeshabilitado = false
      this.validarValores = {}
      this.pago = {
        // Campos obligatorios
        mep: '', // (ID  del método de pago)
        mone: '', // (ID de la moneda)
        emp: '', // (ID de la empresa)
        suc: '', // (ID de la sucursal)
        mon: '', // (monto)
        tas: '', // (tasa)
        ref: '', // (referencia)
        est: '', // (estado del pago)
        fecRe: '', // (Fecha de recibo)
        fecDe: '', // (fecha depósito)
        cba: '', // Cuenta de banco
        fac: '', // factura
        est_fac: '', // estatus factura
        obs_fac: '', // observaciones de factura
        // Campos opcionales
        obs: '', // observacion
        ind: '' // indexación (number)
      }
      this.monedaPagoSeleccionado = ''
      this.cargando = false
      this.resumenPago = false
      this.timeout = null
      this.clientes = []
      this.sucursales = []
      this.vendedores = []
    },
    validarPago (skip) {
      const pagoActual = JSON.parse(JSON.stringify(this.pago))
      const esMetodoPagoSinCuenta = pagoActual.mep.req_cba_mep === '0'
      const pagoFormateado = this.formatearPago(this.pago)
      // En caso el pago se realice a una cuenta se utiliza el id de la moneda de la cuenta
      if (pagoFormateado.mep && pagoFormateado.cba && !esMetodoPagoSinCuenta) {
        pagoFormateado.mone = Number(pagoActual.cba.id_mon_cba)
      }
      this.botonDeshabilitado = true
      let error = false
      this.validarValores = {
        mep: pagoFormateado.mep.length ? 'valido' : 'invalido',
        emp: pagoFormateado.emp.length ? 'valido' : 'invalido',
        suc: pagoFormateado.suc && pagoFormateado.suc.length ? 'valido' : 'invalido',
        mon: pagoFormateado.mon.length ? 'valido' : 'invalido',
        tas: pagoFormateado.tas.length ? 'valido' : 'invalido',
        ref: esMetodoPagoSinCuenta ? '' : pagoFormateado.ref && pagoFormateado.ref.length ? 'valido' : 'invalido',
        est: pagoFormateado.est.length ? 'valido' : 'invalido',
        cba: esMetodoPagoSinCuenta ? '' : pagoFormateado.cba && pagoFormateado.cba.length ? 'valido' : 'invalido',
        fecRe: pagoFormateado.fecRe.length && this.validarFechaMenorAHoy(pagoFormateado.fecRe) ? 'valido' : 'invalido',
        fecDe: pagoFormateado.fecDe.length && this.validarFechaMenorAHoy(pagoFormateado.fecDe) ? 'valido' : 'invalido',
        mone: esMetodoPagoSinCuenta && !pagoFormateado.mone ? 'invalido' : 'valido',
        fac: pagoFormateado.fac && pagoFormateado.fac.length ? 'valido' : 'invalido',
        est_fac: pagoFormateado.est_fac && pagoFormateado.est_fac.length ? 'valido' : 'invalido',
        fec_pag_fac: pagoFormateado.est_fac === 'Solvente'
          ? (pagoFormateado.fec_pag_fac && pagoFormateado.fec_pag_fac.length && this.validarFechaMenorAHoy(pagoFormateado.fec_pag_fac) ? 'valido' : 'invalido')
          : ''
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false
        error = true
        return this.mostrarAlerta('Todos los campos son requeridos', 'advertencia')
      }
      if (error) {
        this.botonDeshabilitado = false
        return
      }
      // Aplica formato Capitalize a la observacion
      if (pagoFormateado.obs) pagoFormateado.obs = capitalizar(pagoFormateado.obs)
      if (pagoFormateado.obs_fac) pagoFormateado.obs_fac = capitalizar(pagoFormateado.obs_fac)
      // Eliminar campos vacios
      if (skip) {
        this.resumenPago = true
        return
      }
      const pagoSinCamposVacios = eliminarVacios(pagoFormateado)
      if (this.esEditar) {
        return this.editarPago(pagoSinCamposVacios)
      }
      // agregar cco al pago solo cuando se creará
      pagoSinCamposVacios.cco = '1'
      return this.crearPago(pagoSinCamposVacios)
    },
    formatearPago (entidad) {
      const variableSinReactividad = JSON.parse(JSON.stringify(entidad))
      return ({
        // Campos obligatorios
        aco: variableSinReactividad.aco,
        mep: variableSinReactividad.mep && variableSinReactividad.mep.id_mep, // (ID del método de pago)
        emp: variableSinReactividad.emp && variableSinReactividad.emp.id_emp, // (ID de la empresa)
        mon: variableSinReactividad.mon, // (monto)
        tas: variableSinReactividad.tas, // (tasa)
        ref: variableSinReactividad.ref, // (referencia)
        est: variableSinReactividad.est, // (estado del pago)
        fecRe: variableSinReactividad.fecRe, // (Fecha de recibo)
        fecDe: variableSinReactividad.fecDe, // (fecha depósito)
        cba: variableSinReactividad.cba && variableSinReactividad.cba.id_cba, // (ID cuenta de banco)
        suc: variableSinReactividad.suc && variableSinReactividad.suc.id_suc, // (ID de la sucursal)
        fac: variableSinReactividad.fac && variableSinReactividad.fac.id_fac, // (Factura)
        est_fac: variableSinReactividad.est_fac, // (estatus de la factura)
        fec_pag_fac: variableSinReactividad.fec_pag_fac, // (Fecha del pago)
        // Campos opcionales
        obs: variableSinReactividad.obs, // observacion
        obs_fac: variableSinReactividad.obs_fac, // observacion
        ind: variableSinReactividad.ind, // indexación (number)
        mone: variableSinReactividad.mone && variableSinReactividad.mone.id_mon // (ID de la moneda)
      })
    },
    crearPago (entidad) {
      const variablesPost = JSON.parse(JSON.stringify(entidad))
      apiPost({ s: 'crearPago' }, JSON.stringify(variablesPost))
        .then(({ data }) => {
          if (data && data.pago.r && data.fac.r) {
            this.mostrarAlerta('El pago ha sido agregado correctamente', 'correcto')
            this.limpiarCampos()
            this.$emit('crearPago')
            return this.$emit('cerrar', false)
          }
          this.botonDeshabilitado = false
          this.mostrarAlerta('Ha ocurrido un error agregando el pago, por favor intenta de nuevo', 'error')
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.mostrarAlerta('Ha ocurrido un error agregando el pago, por favor intenta de nuevo', 'error')
        })
    },
    editarPago (entidad) {
      const variablesPost = JSON.parse(JSON.stringify(entidad))
      apiPost({ s: 'pagoEditar' }, JSON.stringify(variablesPost))
        .then(({ data }) => {
          if (data && data.r) {
            this.mostrarAlerta('El pago ha sido actualizado correctamente', 'correcto')
            this.limpiarCampos()
            this.$emit('editarPago')
            const factura = {
              est_fac: entidad.est_fac,
              fac: entidad.fac
            }
            apiPost({ s: 'facturaEditarM' }, JSON.stringify(factura))
              .then(({ data }) => {
                if (data && data.r) {
                  this.alertaMensaje = {
                    contenido: 'El estatus ha sido actualizado correctamente',
                    tipo: 'correcto'
                  }
                }
              })
              .catch(() => {
                this.botonDeshabilitado = false
                this.alertaMensaje = {
                  contenido: 'Ha ocurrido un error cambiando el estatus de la factura, por favor intenta de nuevo',
                  tipo: 'error'
                }
              })
            this.$emit('actualizar')
            return this.$emit('cerrar', false)
          }
          this.botonDeshabilitado = false
          this.mostrarAlerta('Ha ocurrido un error agregando el pago, por favor intenta de nuevo', 'error')
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.mostrarAlerta('Ha ocurrido un error actualizando el pago, por favor intenta de nuevo', 'error')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
// Oculta el overflow de transición
.modal-dialog-scrollable .modal-body {
  overflow-x: hidden;
}

.lista-resumen-pago {
  list-style: none;
  padding: 0;
  overflow: hidden;

  li {
    line-break: anywhere;
    margin-bottom: .5rem;
  }
}
</style>
