import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars, faBoxes, faCheckCircle, faCalendarAlt, faEllipsisV, faExclamationCircle, faInfoCircle,
  faList, faMapMarkedAlt, faSignOutAlt, faTimes, faTimesCircle, faUsers, faPrint, faFilePdf,
  faCalendarTimes, faChartBar, faWalking, faPhoneAlt, faBan, faClipboardList, faMoneyBillWave,
  faShippingFast, faStore, faFileInvoice, faMinus, faPlus, faClipboard, faChevronDown, faAngleRight,
  faAt, faCalendar, faSearch, faCalendarWeek, faCalendarMinus, faMapSigns, faClock, faSearchDollar, faPen,
  faHandHoldingDollar
} from '@fortawesome/free-solid-svg-icons'
import { /* Regulares */ } from '@fortawesome/free-regular-svg-icons'
import { /* Brands */ } from '@fortawesome/free-brands-svg-icons'

library.add(
  // Solid
  faBars, faBoxes, faCheckCircle, faCalendarAlt, faEllipsisV, faExclamationCircle, faInfoCircle,
  faList, faMapMarkedAlt, faSignOutAlt, faTimes, faTimesCircle, faUsers, faPrint, faFilePdf,
  faCalendarTimes, faChartBar, faWalking, faPhoneAlt, faBan, faClipboardList, faMoneyBillWave,
  faShippingFast, faStore, faFileInvoice, faMinus, faPlus, faClipboard, faChevronDown, faAngleRight,
  faAt, faCalendar, faSearch, faCalendarWeek, faCalendarMinus, faMapSigns, faClock, faSearchDollar, faPen,
  faHandHoldingDollar
  // Regular
  // Brand
)
