import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import 'vue-select/dist/vue-select.css' // Estilos del vue-select
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vSelect from 'vue-select'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './fa-icons'

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
