<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalEditarFactura"
      @close="$emit('cerrar', false); limpiarCampos()"
    >
      <mdb-modal-header class="pb-0">
        <mdb-modal-title>
          Editar Factura
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="overflow-hidden">
        <div class="row">
          <div class="col-12 col-md-4">
            <mdb-input
              v-model="factura.fec_fac"
              type="date"
              label="Fecha de emisión"
              :small="feedback.fec_fac"
              :class="['my-2', validarValores.fec_fac]"
              outline
            />
          </div>
          <div class="col-12 col-md-4">
            <mdb-input
              v-model="factura.fec_ven_fac"
              type="date"
              label="Fecha de vencimiento"
              :class="['my-2', validarValores.fec_ven_fac]"
              outline
            />
          </div>
          <div class="col-12 col-md-4">
            <div class="md-form md-outline outline-select my-2">
              <select
                v-model="factura.est_fac"
                id="estatus-select"
                class="custom-select"
              >
                <option class="d-none" value="" disabled>
                  Seleccione
                </option>
                <option v-for="(estatus , i) in EstatusFactura" :key="i"
                  :value="estatus.nombre"
                >
                  {{estatus.nombre}}
                </option>
              </select>
              <label
                for="estatus-select"
                :class="factura.est_fac && factura.est_fac.length ? 'label-active' : 'label-inactive'"
              >
                Estatus
              </label>
            </div>
          </div>
          <div
            v-if="factura.est_fac == 'Solvente'"
            class="col-12 col-md-4"
          >
              <mdb-input
                v-model="factura.fec_pag_fac"
                type="date"
                label="Fecha de pago"
                :class="['my-2', validarValores.fec_pag_fac]"
                invalidFeedback="Si la factura esta solvente, indica la fecha del pago. Debe ser antes de la fecha de hoy"
                outline
              />
          </div>
          <div :class="['col-12', factura.est_fac == 'Solvente' ? 'col-md-4' : 'col-md-6']">
            <mdb-input
              v-model="factura.mon_fac"
              type="number"
              label="Monto"
              :class="['my-2', validarValores.mon_fac]"
              outline
            />
          </div>
          <div :class="['col-12', factura.est_fac == 'Solvente' ? 'col-md-4' : 'col-md-6']">
            <mdb-input
              v-model="factura.num_fac"
              type="number"
              label="N° de factura"
              :class="['my-2', validarValores.num_fac]"
              outline
            />
          </div>
          <div class="col-12">
            <mdb-input
              v-model="factura.obs_fac"
              type="textarea"
              label="Observación"
              class="my-2"
              outline
            />
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          flat
          dark-waves
          icon="times"
          @click="$emit('cerrar', false); limpiarCampos()"
        >
          Cancelar
        </mdb-btn>
        <mdb-btn
          color="primario"
          icon="sync"
          :disabled="!Object.keys(this.factura).length"
          @click="actualizarFactura()"
        >
          Actualizar
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado } from '@/funciones/funciones.js'
import { EstatusFactura } from '@/constantes/pagos'
import AlertaMensaje from '@/components/AlertaMensaje.vue'

import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput
} from 'mdbvue'
export default {
  name: 'ModalEditarFactura',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    AlertaMensaje
  },
  props: {
    mostrarModalEditarFactura: {
      type: Boolean,
      required: true
    },
    facturaSeleccionada: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      asignarColorEstado,
      EstatusFactura,
      alertaMensaje: { contenido: '' },
      validarValores: {},
      factura: {},
      feedback: {}
    }
  },
  mounted () {
  },
  watch: {
    facturaSeleccionada: function (valor) {
      if (valor) {
        this.factura = valor
      }
    }
  },
  methods: {
    validarFechaMenorAHoy (fecha) {
      const hoy = new Date()
      const fechaFormulario = new Date(fecha)
      hoy.setHours(0, 0, 0, 0)
      if (hoy <= fechaFormulario) {
        this.feedback.fec_fac = 'La fecha debe ser el día de hoy o antes de hoy'
        return false
      }
      return true
    },
    actualizarFactura () {
      this.validarValores = {
        fec_fac: this.factura.fec_fac && this.validarFechaMenorAHoy(this.factura.fec_fac) ? 'valido' : 'invalido',
        est_fac: this.factura.est_fac ? 'valido' : 'invalido',
        fec_ven_fac: this.factura.fec_ven_fac ? 'valido' : 'invalido',
        fec_pag_fac: this.factura.est_fac === 'Solvente' ? (this.factura.fec_pag_fac ? 'valido' : 'invalido') : '',
        id_fac: this.factura.id_fac ? 'valido' : 'invalido',
        mon_fac: this.factura.mon_fac ? 'valido' : 'invalido',
        num_fac: this.factura.num_fac ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'advertencia'
        }
      } else {
        const data = {
          fec_fac: this.factura.fec_fac,
          est_fac: this.factura.est_fac,
          fec_ven_fac: this.factura.fec_ven_fac,
          fec_pag_fac: this.factura.fec_pag_fac,
          fac: this.factura.id_fac,
          mon_fac: this.factura.mon_fac,
          num_fac: this.factura.num_fac,
          obs_fac: this.factura.obs_fac
        }
        apiPost({ s: 'facturaEditarM' }, data)
          .then((res) => {
            if (res && res.data.r) {
              this.alertaMensaje = {
                contenido: 'La factura se ha actualizado',
                tipo: 'correcto'
              }
              this.$emit('actualizar')
              this.limpiarCampos()
            } else {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando la factura, por favor intenta de nuevo',
                tipo: 'error'
              }
            }
          })
      }
    },
    limpiarCampos () {
      this.factura = {}
      this.validarValores = {}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
