import Vue from 'vue'
import VueRouter from 'vue-router'
import Pago from '../views/Pago.vue'
import Pagos from '../views/Pagos.vue'
import Facturas from '../views/Facturas.vue'
import Clientes from '../views/Clientes.vue'
import Cliente from '../views/Cliente.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)
const pushPrototype = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return pushPrototype.call(this, location).catch((e) => e)
}

const routes = [
  {
    path: '/',
    redirect: '/pagos'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: Pagos,
    children: [
      {
        path: ':id',
        name: 'Pago',
        component: Pago
      }
    ]
  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: Facturas
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes,
    children: [
      {
        path: ':id',
        name: 'Cliente',
        component: Cliente
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    if (document.getElementById('app')) {
      document.getElementById('app').scrollIntoView()
    }
  }
})

export default router
