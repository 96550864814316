<template>
  <mdb-modal
    centered
    scrollable
    :show="mostrarModal"
    @close="$emit('cerrar')"
  >
    <mdb-modal-body>
      <font-awesome-icon
        icon="exclamation-circle"
        size="5x"
        class="icono animated rotateIn"
      />
      <header class="h4-responsive">{{ titulo }}</header>
      <p
        v-if="mensaje.length"
        class="px-sm-3"
      >
        {{ mensaje }}
      </p>
      <div
        :class="['row justify-content-around mt-4', {'cursor-not-allowed': botonDeshabilitado}]"
        :title="botonDeshabilitado ? 'Espera por favor, estamos procesando la solicitud' : ''"
      >
        <mdb-btn
          flat
          dark-waves
          icon="times"
          :disabled="botonDeshabilitado"
          @click="$emit('cerrar')"
        >
          Cancelar
        </mdb-btn>
        <mdb-btn
          color="error"
          :icon="botonDeshabilitado ? 'circle-notch' : iconoBotonConfirmar"
          :icon-class="[{'fa-spin': botonDeshabilitado}]"
          :disabled="botonDeshabilitado"
          @click="$emit('confirmar')"
        >
          {{textoBotonConfirmar}}
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { mdbBtn, mdbModal, mdbModalBody } from 'mdbvue'
export default {
  name: 'ConfirmacionEliminar',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody
  },
  props: {
    botonDeshabilitado: {
      type: Boolean,
      required: true
    },
    iconoBotonConfirmar: {
      type: String,
      default: 'check'
    },
    mensaje: {
      type: String,
      default: ''
    },
    mostrarModal: {
      type: Boolean,
      required: true
    },
    titulo: {
      type: String,
      default: '¿Está seguro de proceder?'
    },
    textoBotonConfirmar: {
      type: String,
      default: 'Eliminar'
    }
  }

}
</script>

<style lang="scss" scoped>
.modal-body {text-align: center;}

.icono {
  color: $error;
  margin: 1.5rem 0;
}

header {margin-bottom: .75rem;}

p {
  color: $gris-muted;
  line-height: 1.3;
}
</style>
