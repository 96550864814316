const EstatusPago = [
  {
    nombre: 'Notificado'
  },
  {
    nombre: 'Rechazado'
  },
  {
    nombre: 'Confirmado'
  }
]

const EstatusFactura = [
  {
    nombre: 'Por cobrar'
  },
  {
    nombre: 'Solvente'
  },
  {
    nombre: 'Excepción'
  }
]

const EstatusPagoIconos = {
  Notificado: 'exclamation-circle',
  Rechazado: 'times-circle',
  Confirmado: 'check-circle',
  Auditado: 'search-dollar'
}

export {
  EstatusPago,
  EstatusFactura,
  EstatusPagoIconos
}
