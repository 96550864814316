<template>
  <section>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModal"
      @close="limpiarCampos()"
    >
      <mdb-modal-header class="pb-0">
        <mdb-modal-title>
          Restringir condición de pago
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row">
          <div class="col-12 col-md-4">
            <v-select
              v-model="conPagoSeleccionado"
              :searchable="false"
              :options="conPagoSelect"
              :getOptionLabel="conPagoSelect => conPagoSelect.nom_cp"
              :reduce="conPagoSelect => conPagoSelect.id_cp"
              class="outline-datalist my-2"
            >
              <template #header>
                <label :class="['datalist-label', {'activo': conPagoSeleccionado.includes(conPagoSelect.id_cp)}]">
                  Condición de pago
                </label>
              </template>
              <template #option="conPagoSelect">
                {{conPagoSelect.nom_cp}}
              </template>
            </v-select>
          </div>
          <div class="col-12">
            <mdb-input
              v-model="observacion"
              type="textarea"
              label="Observación"
              :class="['my-2', validarValores.observacion]"
              outline
            />
          </div>
        </div>
        <mdb-modal-footer class="pb-0">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            @click="limpiarCampos()"
          >
            Cancelar
          </mdb-btn>
          <mdb-btn
            color="primario"
            icon="sync"
            @click="agregarRestriccion()"
          >
            Guardar
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'

import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput
} from 'mdbvue'
export default {
  name: 'ModalRestringirTipoPre',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    AlertaMensaje
  },
  props: {
    mostrarModal: {
      type: Boolean,
      default: false,
      required: true
    },
    idSucursalSeleccionada: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      validarValores: {},
      conPagoSelect: [],
      conPagoSeleccionado: [],
      observacion: ''
    }
  },
  mounted () {
    this.cargaInicial()
  },
  watch: {
  },
  methods: {
    cargaInicial () {
      apiPost({ s: 'cond_pagoMv' })
        .then((res) => {
          this.conPagoSelect = res.data.cp
        })
    },
    agregarRestriccion () {
      this.validarValores = {
        observacion: this.observacion.length > 10 ? 'valido' : 'invalido',
        conPagoSeleccionado: this.conPagoSeleccionado.length ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'advertencia'
        }
      } else {
        const data = {
          obs: this.observacion,
          cp: this.conPagoSeleccionado,
          suc: this.idSucursalSeleccionada
        }
        apiPost({ s: 'crearRestriccionConPagoMv' }, data)
          .then((res) => {
            if (res && res.data.r) {
              this.alertaMensaje = {
                contenido: 'Guardadas restricciones',
                tipo: 'correcto'
              }
              this.$emit('cargaInicial')
              this.limpiarCampos()
            } else {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error guardando las restricciones, por favor intenta de nuevo',
                tipo: 'error'
              }
            }
          })
      }
    },
    limpiarCampos () {
      this.conPagoSeleccionado = []
      this.observacion = ''
      this.$emit('cerrar')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
